import { trackUserAction } from '@local/metrics';
import { WDSThemeContext } from '@local/web-design-system-2/dist/theme/WDSThemeProvider';
import AppsIcon from '@mui/icons-material/Apps';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';

import { AppsMenu } from './AppsMenu/AppsMenu';
import { AppsMenuProps } from './AppsMenu/AppsMenu.types';
import { useStyles } from './AppsMenuButton.styles';
import { Categories, UserAction } from './metrics.types';

function AppsMenuButton({ color, size, variant = 'light' }: AppsMenuProps) {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // If this context was used higher up in the application's DOM, `isMounted` will be true. If it's not used, it
    // defaults to false.
    const { isMounted: isWDS2Enabled } = useContext(WDSThemeContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        trackUserAction(UserAction.WAFFLE_MENU_OPENED, { category: Categories.WAFFLE_MENU });
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const darkTheme = variant === 'dark';
    const iconClass = classnames({
        [classes.iconLegacy]: !isWDS2Enabled,
        [classes.iconDarkLegacy]: !isWDS2Enabled && darkTheme,
    });
    const open = Boolean(anchorEl);

    return (
        <>
            {isWDS2Enabled ? (
                <IconButton
                    onClick={handleClick}
                    className={classnames({
                        [classes.buttonLegacy]: !isWDS2Enabled,
                        [classes.rootDarkLegacy]: !isWDS2Enabled && darkTheme,
                    })}
                    color={color}
                    size={size}
                    automation-id="waffle-menu-button"
                >
                    <AppsIcon fontSize="medium" className={iconClass} automation-id="apps-icon" />
                </IconButton>
            ) : null}
            {/**
             * Devnote: We can't extract these components for re-use, it breaks the anchorEl reference and makes
             * the Popover render on the side and emit console warnings.
             *
             * When all apps have migrated to using WDS2, remove all of this anyway.
             */}
            {!isWDS2Enabled ? (
                <Grid
                    xs
                    item
                    container
                    wrap="nowrap"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classnames(classes.rootLegacy, {
                        [classes.openLegacy]: open,
                        [classes.openDarkLegacy]: open && darkTheme,
                    })}
                >
                    <IconButton
                        onClick={handleClick}
                        className={classnames({
                            [classes.buttonLegacy]: !isWDS2Enabled,
                            [classes.rootDarkLegacy]: !isWDS2Enabled && darkTheme,
                        })}
                        color={color}
                        automation-id="waffle-menu-button"
                    >
                        <AppsIcon
                            fontSize="large"
                            className={iconClass}
                            automation-id="apps-icon"
                        />
                    </IconButton>
                </Grid>
            ) : null}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                className={classnames({
                    [classes.contentContainerLegacy]: !isWDS2Enabled,
                })}
            >
                <AppsMenu variant={variant} isWDS2Enabled={isWDS2Enabled} />
            </Popover>
        </>
    );
}

export default AppsMenuButton;
